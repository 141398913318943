const type = {
  t1: {
    scale: [72, 60, 38],
    styles: {
      fontWeight: 700,
      textTransform: "uppercase",
      lettingSpacing: 1.64,
      lineHeight: 1.2,
    },
  },
  t2: {
    scale: [58, 44, 31],
    styles: {
      fontWeight: 700,
      textTransform: "uppercase",
      letterSpacing: 1.32,
      lineHeight: 1.2,
    },
  },
  t3: {
    scale: [36, 31, 26],
    styles: {
      fontWeight: 500,
      letterSpacing: -0.97,
      lineHeight: 1.22,
    },
  },
  t4: {
    scale: [22, 18, 16],
    styles: {
      fontWeight: 500,
    },
  },
  t4sm: {
    scale: [20, 16, 14],
    styles: {
      fontWeight: 500,
    },
  },
  t5lg: {
    scale: [16, 14, 13],
    styles: {
      fontWeight: 700,
      lineHeight: 1.25,
      textTransform: "uppercase",
      letterSpacing: "0.109em",
    },
  },
  t5md: {
    scale: [14, 13, 12],
    styles: {
      fontWeight: 700,
      lineHeight: 1.25,
      textTransform: "uppercase",
      letterSpacing: "0.09em",
    },
  },
  t5sm: {
    scale: [13, 11],
    styles: {
      fontWeight: 700,
      lineHeight: 1.4,
      textTransform: "uppercase",
      letterSpacing: "0.091em",
    },
  },
  t6: {
    scale: [15, 13, 12],
    styles: {
      fontWeight: 700,
      lineHeight: 1.2,
      letterSpacing: "0.11em",
      textTransform: "uppercase",
    },
  },
  tableHeader: {
    scale: [14, 12, 11],
    styles: {
      fontWeight: 700,
      letterSpacing: "0.02em",
    },
  },
  cardTitles: {
    scale: [20, 18, 15],
    styles: {
      fontWeight: 500,
      lineHeight: 1.4,
    },
  },
  cardTitlesSM: {
    scale: [19, 18, 17],
    styles: {
      fontWeight: 500,
    },
  },
  previewText: {
    scale: [14, 11, 12],
    styles: {
      fontWeight: 400,
      lineHeight: 1.5,
    },
  },
  secondaryHighlight: {
    scale: [14, 12],
    styles: {
      lineHeight: 1.7,
      letterSpacing: "-0.01em",
    },
  },
  postMetaData: {
    scale: [13, 12],
    styles: {
      fontStyle: "italic",
    },
  },
  statDescription: {
    scale: [16, 15, 13],
    styles: {
      lineHeight: 1.4,
    },
  },
  statValue: {
    scale: [42, 38, 36],
    styles: {
      fontWeight: 500,
      lineHeight: 1,
      letterSpacing: "-0.027em",
    },
  },
  statFootnote: {
    scale: [14, 13],
    styles: {
      lineHeight: 1.4,
      fontStyle: "italic",
    },
  },
  highlightCardDesc: {
    scale: [16, 15],
    styles: {
      lineHeight: 1.4,
      letterSpacing: 0,
    },
  },
  highlightCardIconLikeText: {
    scale: [60, 50, 40],
    styles: {
      fontWeight: 500,
      lineHeight: 1,
      letterSpacing: "-1.62px",
    },
  },
  bodyCopy: {
    scale: [17, 15, 14],
    styles: {
      lineHeight: 1.4,
    },
  },
  bodyCopySm: {
    scale: [15, 13, 12],
    styles: {
      fontWeight: 500,
      lineHeight: 1.5,
      letterSpacing: "-0.02667em",
    },
  },
  blockquoteText: {
    scale: [24, 21, 18],
    styles: {
      display: "inline",
      letterSpacing: "0.02em",
    },
  },
  blockquoteCaption: {
    scale: [14, 12, 11],
    styles: {
      lineHeight: 1.4,
    },
  },
  blockquoteTextSM: {
    scale: [17, 15, 13.5],
    styles: {
      fontStyle: "italic",
      lineHeight: 1.4,
    },
  },
  copyright: {
    scale: [9, 10],
    styles: {
      lineHeight: 1.4,
      letterSpacing: "0.037em",
      textTransform: "uppercase",
    },
  },
  footerNavSection: {
    scale: [14, 16, 14],
    styles: {
      fontWeight: 500,
      letterSpacing: "-0.15",
    },
  },
  footerNavLink: {
    scale: [14, 15, 13],
    styles: {
      letterSpacing: "-0.15",
    },
  },
  imageCaption: {
    scale: [14, 12, 11],
    styles: {
      lineHeight: 1.4,
    },
  },
  label: {
    scale: [12, 12, 11],
    styles: {
      fontWeight: 600,
      lineHeight: 1.75,
      textTransform: "uppercase",
      letterSpacing: "0.125em",
    },
  },
  headerNavLink: {
    scale: [15, 16],
    styles: {
      fontWeight: 500,
      letterSpacing: "-0.16px",
    },
  },
  headerBold: {
    styles: {
      fontSize: 13,
      fontWeight: 700,
      textTransform: "uppercase",
      letterSpacing: "0.016em",
    },
  },
  headerDropdownLink: {
    scale: [13, 14],
    styles: {
      fontWeight: 500,
      letterSpacing: -0.14,
    },
  },
  headerHighlight: {
    styles: {
      fontSize: 13,
      letterSpacing: -0.14,
      lineHeight: 1.4,
    },
  },
  headerHighlightLink: {
    styles: {
      fontSize: 11,
      fontWeight: 400,
    },
  },
  audioPlayerTitle: {
    styles: {
      fontSize: 12,
      fontWeight: 500,
      letterSpacing: -0.13,
    },
  },
  audioPlayerSubtitle: {
    styles: {
      fontSize: 12,
      fontStyle: "italic",
      letterSpacing: -0.12,
    },
  },
  storyCardDate: {
    styles: {
      fontSize: 11,
      fontWeight: 400,
      fontStyle: "italic",
    },
  },
}

export const { scales, styles } = Object.entries(type).reduce(
  (acc, [name, definition]) => {
    if (definition.scale) {
      acc.scales[name] = definition.scale
    }
    acc.styles[name] = definition.styles.fontSize
      ? definition.styles
      : { ...definition.styles, fontSize: `var(--${name})` }
    return acc
  },
  { scales: {}, styles: {} }
)

export default styles
