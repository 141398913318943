import { useStaticQuery, graphql } from "gatsby"

const query = graphql`
  {
    navigation: sanitySite {
      sections: headerNavSections {
        title
        subsections {
          title
          icon {
            ...Image
          }
          links {
            title
            url
          }
          contacts {
            ... on SanityEmailContact {
              type: _type
              body
              email
              title
            }
            ... on SanityPhoneContact {
              type: _type
              body
              title
            }
          }
        }
        highlight: navTextHighlight {
          title
          body
          icon {
            ...Image
          }
          button {
            title
            url
          }
        }
      }
    }
  }
`

export default () => {
  const { navigation } = useStaticQuery(query)
  return navigation.sections
}
