import React from "react"
import PropTypes from "prop-types"
import { jsx } from "@emotion/react"
import cloneElement from "lib/cloneElement"
import Link from "components/Link"
import theme from "styles/theme"

import { ReactComponent as Arrow } from "images/icons/arrow.svg"

const CTAButton = ({
  size = "md",
  children,
  icon,
  colorScheme = "dark",
  className,
  href,
  targetBlank = false,
  onClick,
}) => {
  const styles = sizes[size]
  const Icon = icon || (typeof icon === "undefined" && <Arrow />)

  return jsx(
    href ? Link : "button",
    {
      href,
      onClick,
      target: targetBlank ? "_blank" : null,
      css: [buttonBase, colors[colorScheme], styles],
      className,
    },
    <>
      {children}

      {Icon &&
        cloneElement(Icon, {
          css: [
            {
              width: "var(--iconWidth)",
              marginLeft: "var(--iconSpacing)",
            },
            Icon.props.css,
          ],
        })}
    </>
  )
}

export default CTAButton

CTAButton.propTypes = {
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  children: PropTypes.node.isRequired,
  hideArrow: PropTypes.bool,
  className: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
  href: PropTypes.string,
  targetBlank: PropTypes.bool,
  onClick: PropTypes.func,
  colorScheme: PropTypes.oneOf(["standard", "dark", "light"]),
}

export const buttonBase = {
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  fontWeight: 500,
  lineHeight: 1,
  border: 0,
  borderRadius: 0,
  cursor: "pointer",
  transitionProperty: "color, background-color, box-shadow",
  transitionDuration: "200ms",
  boxShadow: "var(--shadowSize) var(--shadowSize) var(--shadowColor)",
  userSelect: "none",
}

export const sizes = {
  lg: {
    fontSize: 16,
    padding: "18px 19px",
    "--shadowSize": "3px",
    "--iconSpacing": "15px",
    "--iconWidth": "24px",
    [theme.tablet]: {
      fontSize: 14,
      padding: "15px 17px",
      "--iconWidth": "21px",
    },
    [theme.mobile]: {
      fontSize: 13,
      padding: "14px 15px",
      "--iconSpacing": "12px",
      "--iconWidth": "19px",
    },
  },

  md: {
    fontSize: 15,
    padding: "17px 18px",
    "--shadowSize": "3px",
    "--iconSpacing": "18px",
    "--iconWidth": "23px",
    [theme.tablet]: {
      fontSize: 13,
      padding: "13px 15px",
      "--iconSpacing": "13px",
      "--iconWidth": "20px",
    },
    [theme.mobile]: {
      fontSize: 12,
      padding: "12px 13px",
      "--iconSpacing": "11px",
      "--iconWidth": "18px",
    },
  },

  sm: {
    fontSize: 11,
    padding: 11,
    "--shadowSize": "2px",
    "--iconSpacing": "7px",
    "--iconWidth": "13px",
  },
}

export const colors = {
  standard: {
    background: theme.white,
    color: theme.darkGray,
    "--shadowColor": theme.deepBlue,
    svg: {
      color: theme.midBlue,
    },
    ":hover": {
      background: theme.lightBlue30,
    },
  },
  dark: {
    backgroundColor: theme.midBlue,
    color: theme.white,
    "--shadowColor": theme.lightBlue,
    ":hover": {
      backgroundColor: theme.deepBlue,
    },
  },
  light: {
    backgroundColor: theme.white,
    color: theme.midBlue,
    "--shadowColor": theme.midBlue,
    ":hover": {
      backgroundColor: theme.midBlue,
      color: theme.white,
      "--shadowColor": theme.lightBlue,
    },
  },
}
