import { useStaticQuery, graphql } from "gatsby"

const query = graphql`
  {
    navigation: sanitySite {
      sections: footerNavSections {
        _key
        title
        links {
          _key
          title
          url
        }
      }
      legalLinks: footerLegalLinks {
        _key
        title
        url
      }
      copyright
      socialLinks {
        linkedin
        twitter
        instagram
        youtube
      }
    }
  }
`

export default () => {
  const { navigation } = useStaticQuery(query)
  return navigation
}
