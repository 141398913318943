import { useEffect, useState } from "react"
import debounce from "lodash-es/debounce"

export default (initialValue, ttl = 100) => {
  const [height, setHeight] = useState(initialValue)

  useEffect(() => {
    const calculateHeight = debounce(() => {
      setHeight(window.innerHeight)
    }, ttl)

    calculateHeight()
    window.addEventListener("resize", calculateHeight)

    return () => {
      window.removeEventListener("resize", calculateHeight)
    }
  }, [ttl])

  return height
}
