import React, { useState, useCallback } from "react"
import PropTypes from "prop-types"
import {
  DropdownContent,
  DropdownColumn,
} from "components/navigation/Header/Dropdown"
import Highlight from "components/navigation/Header/Highlight"
import InvisibleButton from "components/buttons/InvisibleButton"
import theme from "styles/theme"

const DesktopNav = ({ sections, className }) => (
  <NavContainer className={className}>
    {sections.map((section, index) => (
      <NavItem
        {...section}
        key={`${section.title}-${index}`}
        lastItem={index + 1 === sections.length}
      />
    ))}
  </NavContainer>
)

export default DesktopNav

const NavContainer = props => (
  <div
    css={{
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
    }}
    {...props}
  />
)

const NavItem = ({ title, subsections, highlight, lastItem = false }) => {
  const [enabled, setEnabled] = useState(true)
  const [open, setOpen] = useState(false)

  const toggleOpenState = () => {
    setOpen(prev => !prev)
  }

  const temporarilyDisableHover = useCallback(() => {
    setEnabled(false)
    global.setTimeout(() => setEnabled(true), 500)
  }, [])

  return (
    <div
      css={[
        {
          position: subsections.length <= 3 && "relative",
          color: theme.gray,
          marginLeft: 20,
          ".navContent": {
            display: "grid",
            transformOrigin: "top center",
            transform: "rotateX(90deg)",
            opacity: 0,
            pointerEvents: "none",
          },
        },
        open
          ? openNavItemStyles
          : {
              ":hover": enabled && openNavItemStyles,
            },
      ]}
    >
      <InvisibleButton
        css={{
          ...theme.headerNavLink,
          position: "relative",
          padding: "16px 10px",
          cursor: "pointer",
        }}
        className="navLink"
        onClick={toggleOpenState}
      >
        {title}
      </InvisibleButton>

      <NavContent
        columns={subsections}
        highlight={highlight}
        css={{
          transition: "opacity 250ms, transform 250ms",
          visibility: open ? "visible" : "hidden",
        }}
        className="navContent"
        alignment={
          subsections.length > 3
            ? "justify"
            : lastItem
            ? "offsetLeft"
            : "center"
        }
        onNavigate={() => {
          temporarilyDisableHover()
          setOpen(false)
        }}
        aria-hidden={!open}
      />
    </div>
  )
}

const openNavItemStyles = {
  color: theme.midBlue,
  ".navLink:after": {
    content: "''",
    position: "absolute",
    left: 10,
    right: 10,
    bottom: 0,
    height: 3,
    background: theme.midBlue,
  },
  ".navContent": {
    opacity: 1,
    transform: "rotateX(0)",
    pointerEvents: "initial",
    visibility: "visible",
  },
}

const NavContent = ({
  columns,
  highlight,
  alignment,
  className,
  onNavigate,
  ...props
}) => {
  // This will combine the contents of the last two columns if there are
  // more than 6 columns in the data. This is a bit of a hack to avoid
  // the need for an additional layer in the schema for the moment.
  let columnData
  const {
    length,
    [length - 2]: penultimate,
    [length - 1]: ultimate,
    ...rest
  } = columns
  if (length > 6) {
    columnData = [...Object.values(rest).map(v => [v]), [penultimate, ultimate]]
  } else {
    columnData = columns.map(v => [v])
  }

  return (
    <DropdownContent
      columnCount={length}
      className={className}
      alignment={alignment}
      hasHighlight={!!highlight}
      {...props}
    >
      {columnData.map((data, index) => (
        <DropdownColumn key={index} linkLists={data} onNavigate={onNavigate} />
      ))}

      {highlight && <Highlight type="textHighlight" {...highlight} />}
    </DropdownContent>
  )
}

const linkArrayType = PropTypes.arrayOf(
  PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  })
)

export const columnType = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.shape({
    hotspot: PropTypes.object,
    crop: PropTypes.object,
    asset: PropTypes.object.isRequired,
  }).isRequired,
  links: linkArrayType,
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
      type: PropTypes.oneOf(["emailContact", "phoneContact"]).isRequired,
      email: PropTypes.string,
    })
  ),
}

export const sectionType = {
  title: PropTypes.string.isRequired,
  subsections: PropTypes.arrayOf(PropTypes.shape(columnType)).isRequired,
  highlight: PropTypes.shape(),
}

NavItem.propTypes = {
  ...sectionType,
  lastItem: PropTypes.bool,
}

NavContent.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape(columnType)).isRequired,
  highlight: PropTypes.shape({ ...Highlight.propTypes, type: PropTypes.any }),
  alignment: PropTypes.string,
  className: PropTypes.string,
  onNavigate: PropTypes.func,
}

DesktopNav.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape(sectionType)).isRequired,
  className: PropTypes.string,
}
