const colors = {
  // Shades of Gray
  textGray: "#333333",
  textGrayRgbNums: "51,51,51",
  darkGray: "#565A5C",
  gray: "#828181",
  softGray: "#9F9F9F",
  lightGray: "#E5E5E5",
  slate: "#F1F1F1",
  nearWhite: "#F4F4F4",
  cloud: "#F7F7F7",
  lightCloud: "#FBFBFB",
  overlayGray: "rgba(86, 90, 92, 0.5)", // #565A5C @ 50% opacity
  white: "#FFFFFF",
  whiteRgbNums: "255, 255, 255",

  // Brand Colors
  midBlue: "#00A9DF",
  deepBlue: "#0081C5",
  lightBlue: "#72CDF4",
  lightBlue30: "#D7F1FB",
  blue20: "#E3F5FD",
  orange: "#DF7A00",
  midOrange: "#ECAC59",
  lightOrange: "#FFEAB2",
  lightYellow: "#FFF6DD",
  lighterYellow: "#FFFAEE",

  // UI Colors
  error: "#D0021B",
  errorBG: "#FBEDED",
}

export default colors

export const chartColors = [
  colors.midBlue,
  colors.lightBlue,
  colors.deepBlue,
  colors.lightBlue30,
  "#0095D2",
  "#0C6798",
  colors.orange,
  "#F3CB96",
]

export const splineColors = [
  "#DF7A00",
  "#F3CB96",
  colors.deepBlue,
  colors.midBlue,
]
