import { useEffect, useState } from "react"

export default query => {
  const [match, setMatch] = useState()

  useEffect(() => {
    const mm = window.matchMedia(
      query.startsWith("@media ") ? query.slice(7) : query
    )

    const listener = e => {
      setMatch(e.matches)
    }

    // Listen for changes
    mm.addListener(listener)

    // Set initial value
    setMatch(mm.matches)

    return () => {
      mm.removeListener(listener)
    }
  }, [query, setMatch])

  return match
}
