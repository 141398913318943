import React from "react"
import PropTypes from "prop-types"
import theme from "styles/theme"

import PlayIcon from "images/navigation/playIcon.svg"

const AudioPlayerButton = ({ title, subtitle, fileURL }) => (
  <button
    css={{
      width: 212,
      height: 71,
      border: `1.5px solid ${theme.midBlue}`,
      backgroundColor: theme.blue20,
      padding: 0,
      display: "grid",
      gridTemplateRows: "1fr 7px",
      cursor: "pointer",
      ":hover": {
        backgroundColor: theme.lightBlue30,
      },
    }}
  >
    <div
      css={{
        height: 63,
        alignSelf: "center",
        display: "grid",
        gridTemplateColumns: "58px 1fr",
      }}
    >
      <img
        src={PlayIcon}
        css={{
          height: 26,
          width: 26,
          marginLeft: 16,
          gridRow: "1 / span 2",
          alignSelf: "center",
        }}
        alt="play icon"
      />
      <div
        css={[
          theme.audioPlayerTitle,
          {
            color: theme.midBlue,
            alignSelf: "end",
            justifySelf: "start",
            marginBottom: 2,
          },
        ]}
      >
        {title}
      </div>
      <div
        css={[
          theme.audioPlayerSubtitle,
          {
            color: theme.midBlue,
            alignSelf: "start",
            justifySelf: "start",
            marginTop: 2,
          },
        ]}
      >
        {subtitle}
      </div>
    </div>
    <div
      css={{
        height: 4,
        width: 208.5,
        borderTop: `1.5px solid ${theme.midBlue}`,
        backgroundColor: theme.lightBlue,
        alignSelf: "end",
      }}
    ></div>
  </button>
)

export default AudioPlayerButton

AudioPlayerButton.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  fileURL: PropTypes.string.isRequired,
}
