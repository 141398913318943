import normalize from "emotion-normalize"
import colors from "tokens/colors"
import { styles, scales } from "tokens/type"
import theme, { breakpoints } from "styles/theme"

const [desktopVars, tabletVars, mobileVars] = Object.entries(scales).reduce(
  (acc, [name, breaks]) => {
    breaks.forEach((value, index) => {
      acc[index] = acc[index] || {}
      acc[index][`--${name}`] = `${value}px`
    })
    return acc
  },
  []
)

export default [
  normalize,
  {
    ":root": {
      ...desktopVars,
      [breakpoints.tablet]: tabletVars,
      [breakpoints.mobile]: mobileVars,
    },

    [["*", "*:before", "*:after"]]: {
      boxSizing: "inherit",
    },

    html: {
      fontFamily: theme.font,
      lineHeight: 1.3,
      background: colors.white,
      color: colors.textGray,
      fontSize: 16,
      wordBreak: "break-word",

      // Improve browser font rendering
      textRendering: "optimizeLegibility",
      fontFeatureSettings: "'kern' 1",
      fontKerning: "normal",
      WebkitFontSmoothing: "antialiased",
      MozOsxFontSmoothing: "grayscale",
    },

    body: {
      ...styles.text,
      boxSizing: "border-box",
    },

    a: {
      textDecoration: "none",
      color: "inherit",
    },

    "p > a": {
      textDecoration: "underline",
    },

    [["h1", "h2", "h3", "h4", "h5", "h6"]]: {
      fontSize: "inherit",
      fontWeight: "inherit",
      margin: 0,
    },

    [["img", "svg"]]: {
      maxWidth: "100%",
    },

    svg: {
      display: "block",
    },

    figure: {
      margin: 0,
    },

    strong: { fontWeight: 500 },
  },
]
