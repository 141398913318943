import React, { useEffect, useRef } from 'react'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

// Takes the return of forwardRef() as the "children" prop.
const ScrollLock = ( { children: Child, isActive } ) => {
    const childRef = useRef(null)
    useEffect(() => {
        if (isActive) {
            disableBodyScroll(childRef.current)
        } else {
            enableBodyScroll(childRef.current)
        }
        return () => {
            clearAllBodyScrollLocks()
        }
    }, [isActive, childRef])
    return <Child ref={childRef} />
}

export default ScrollLock
