import { useEffect, useState } from "react"

export default (keys = ["ArrowDown", "ArrowUp"]) => {
  const [ref, setRef] = useState(null)

  useEffect(() => {
    const container = ref?.current || ref

    if (!container) return

    const onKeydown = e => {
      if (keys.includes(e.key)) {
        const match = targetFinders[e.key](e.target, container)

        if (match) {
          e.preventDefault()
          e.stopPropagation()
          match.focus()
        }
      }
    }

    container.addEventListener("keydown", onKeydown)

    return () => {
      container.removeEventListener("keydown", onKeydown)
    }
  }, [ref, keys])

  return setRef
}

const getFocusableElements = container =>
  Array.from(
    container.querySelectorAll(
      ["input", "a", "button", "[tabindex]"]
        .map(selector => selector + ":not([tabindex='-1'])")
        .join(", ")
    )
  )

const nextFocusable = (el, container) => {
  const focusable = getFocusableElements(container)
  return focusable[focusable.indexOf(el) + 1]
}

const prevFocusable = (el, container) => {
  const focusable = getFocusableElements(container)
  return focusable[focusable.indexOf(el) - 1]
}

const targetFinders = {
  ArrowDown: nextFocusable,
  ArrowRight: nextFocusable,
  ArrowUp: prevFocusable,
  ArrowLeft: prevFocusable,
}
