export default (...refs) =>
  element => {
    refs.flat().forEach(ref => {
      if (typeof ref === "function") {
        ref(element)
      } else if (ref !== null) {
        ref.current = element
      }
    })
  }
