import React from "react"
import PropTypes from "prop-types"
import { Link as GatsbyLink } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { URL } from "url"

// Use Node URL on server, browser URL on client-side
const IsomorphicUrl = typeof window !== "undefined" ? window.URL : URL

const TARGET_SELF_HOSTNAMES = [
  "secure.oildexdx.com",
  "ir.marathonoil.com",
  "careers.marathonoil.com",
]

const Link = ({ target, href, ...props }) => {
  if (typeof href !== 'string') {
    href = "/"
  }
  const url = new TidyLink(href || "/")
  if (url.isSafe()) {
    url
      .upgradeSecurity()
      .hostRelative("https://www.marathonoil.com")
      .withTrailingSlash()
  }

  if (href.startsWith("http")) {
    const { hostname } = new IsomorphicUrl(href)
    if (!target && !TARGET_SELF_HOSTNAMES.includes(hostname)) {
      target = "_blank"
    }

    if (target === "_blank") {
      props.rel = "noopener noreferrer"
    }
  }

  return url.isRelative() && target !== "_blank" ? (
    <GatsbyLink to={url.toString()} {...props} />
  ) : (
    <OutboundLink href={url.toString()} target={target} {...props} />
  )
}

export default Link

Link.propTypes = {
  target: PropTypes.string,
  href: PropTypes.string.isRequired,
}

// Lightweight URL formatting to improve consistency
// across site links. This is not a comprehensive URL
// parsing or testing tool.
class TidyLink {
  constructor(url) {
    this.url = url
  }

  upgradeSecurity() {
    this.url = this.url.replace("http://", "https://")
    return this
  }

  hostRelative(base) {
    if (this.url.startsWith(base)) {
      this.url = this.url.slice(base.length)
    }
    return this
  }

  withTrailingSlash() {
    if (
      !this.url.endsWith("/") && // Skip if trailing slash present
      !this.url.startsWith("http") && // Skip non-relative links
      !this.url.includes("?") && // Skip if there's a query string
      !this.url.includes("#") && // Skip if there's a fragment
      !this.url.match(/\.[a-z0-9]{2,6}$/) // Skip if there's a file extension
    ) {
      this.url = this.url + "/"
    }

    return this
  }

  toString() {
    return this.url
  }

  isSafe() {
    // Avoid acting on links with a non-HTTP scheme
    return !(/^[a-z0-9]{2,12}:/.test(this.url) && !this.url.startsWith("http"))
  }

  isRelative() {
    return this.url.startsWith("/")
  }
}
