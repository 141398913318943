import React, { useContext } from "react"
import PropTypes from "prop-types"
import Favicons from "components/Favicons"
import GlobalHeader from "components/navigation/Header"
import GlobalFooter from "components/navigation/Footer"
import { AppContext } from "components/App"
import Search from "components/Search"
import Modal from "components/Modal"
import theme from "styles/theme"

const Layout = ({ children }) => {
  const { searchOpen, setSearchOpen } = useContext(AppContext)

  return (
    <>
      <Favicons />
      <GlobalHeader setSearchOpen={setSearchOpen} />
      <main
        css={{
          "--headerHeight": "116px",
          [theme.navBreak]: { "--headerHeight": "91px" },
          [theme.mobile]: { "--headerHeight": "76px" },
        }}
      >
        {children}
      </main>
      <GlobalFooter />
      {searchOpen && (
        <Modal
          mode="full"
          animationDuration="500ms"
          onRequestClose={() => setSearchOpen(false)}
        >
          <Search inverted />
        </Modal>
      )}
    </>
  )
}

export default Layout

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
