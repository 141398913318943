import colors from "tokens/colors"
import typography from "tokens/type"
import transitions from "tokens/transitions"

export const breakpoints = {
  navBreak: "@media (max-width: 1439px)",
  xl: "@media (min-width: 1440px)",
  desktop: "@media (min-width: 1179px)",
  tablet: "@media (max-width: 1178px)",
  tabletSmall: "@media (max-width: 960px)",
  mobile: "@media (max-width: 767px)",
  mobilePortrait: "@media (max-width: 540px)",
  smallMobilePortrait: "@media (max-width: 369px)",
}

const leftZip = (left, right) =>
  left.reduce((output, value, index) => {
    output[right[index]] = value
    return output
  }, {})

const expandQueries = function recurse(
  styles,
  mediaQueries = ["root", breakpoints.tablet, breakpoints.mobile]
) {
  const { root = {}, ...rest } = Object.entries(styles).reduce(
    (output, [property, values]) => {
      if (Array.isArray(values)) {
        Object.entries(leftZip(values, mediaQueries)).forEach(
          ([query, value]) => {
            output[query] = output[query] || {}
            output[query][property] = value
          }
        )
      } else if (typeof values === "object") {
        output[property] = recurse(values, mediaQueries)
      } else {
        output[property] = values
      }

      return output
    },
    {}
  )

  return { ...root, ...rest }
}

const theme = {
  ...colors,
  ...typography,
  ...breakpoints,
  transitions,
  expandQueries,
  font: '"Gotham A", "Gotham B", Gotham, Helvetica, sans-serif',
  cardShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)",
  visuallyHidden: {
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    whiteSpace: "nowrap",
    width: 1,
  },
  titleLink: (rgb = '255, 255, 255', alpha = '.45') => ({
    cursor: 'pointer',
    textDecoration: 'underline',
    textDecorationThickness: '.07em',
    textUnderlineOffset: '0',
    textDecorationColor: `rgba(${rgb}, 0)`,
    ':hover': {
      textUnderlineOffset: '.145em',
      textDecorationColor: `rgba(${rgb}, ${alpha})`,
    },
  }),
}

export default theme
