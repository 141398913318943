import React from "react"
import Helmet from "react-helmet"

const Favicons = () => (
  <Helmet>
    <link
      rel="apple-touch-icon"
      sizes="180x180"
      href="/favicons/apple-touch-icon.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="32x32"
      href="/favicons/favicon-32x32.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="16x16"
      href="/favicons/favicon-16x16.png"
    />
    <link rel="manifest" href="/favicons/manifest.json" />
    <link
      rel="mask-icon"
      href="/favicons/safari-pinned-tab.svg"
      color="#00a9e0"
    />
    <link rel="shortcut icon" href="/favicons/favicon.ico" />
    <meta name="apple-mobile-web-app-title" content="Marathon Oil" />
    <meta name="application-name" content="Marathon Oil" />
    <meta name="msapplication-config" content="/favicons/browserconfig.xml" />
    <meta name="theme-color" content="#00a9e0" />
  </Helmet>
)

export default Favicons
