import React from "react"
import PropTypes from "prop-types"
import Link from "components/Link"
import theme from "styles/theme"
import {
  DropdownTitle,
  DropdownLink,
} from "components/navigation/Header/Dropdown"
import AudioPlayerButton from "components/buttons/AudioPlayerButton"
import CTAButton from "components/buttons/CTAButton"

const Highlight = ({ type, ...props }) => {
  switch (type) {
    case "textHighlight": {
      const { body, ...rest } = props
      return <TextHighlight {...rest} text={body} />
    }

    case "linksHighlight":
      return <LinksHighlight {...props} />

    default:
      return null
  }
}

export default Highlight

const HighlightLink = ({ title, url }) => (
  <Link
    css={[
      theme.headerHighlightLink,
      {
        color: theme.midBlue,
        display: "block",
        ":hover": {
          textDecoration: "underline",
        },
      },
    ]}
    href={url}
  >
    {title}
  </Link>
)

const HighlightBase = ({ title, icon, className, children }) => (
  <div
    css={{
      backgroundColor: theme.blue20,
      padding: 30,
      marginTop: 40,
      gridColumn: "1 / -1",
      alignSelf: "end",
      [theme.navBreak]: {
        marginTop: 0,
        padding: "50px 66px",
        alignSelf: "unset",
      },
      [theme.mobile]: {
        padding: "50px 72px 60px 40px",
      },
      [theme.smallMobilePortrait]: {
        paddingRight: 40,
      },
    }}
    className={className}
  >
    <DropdownTitle
      title={title}
      icon={icon}
      css={{ marginBottom: 18, [theme.navBreak]: { marginBottom: 25 } }}
    />

    {children}
  </div>
)

export const TextHighlight = ({
  text,
  button,
  textLink,
  onNavigate,
  ...rest
}) => (
  <HighlightBase {...rest}>
    <div
      css={{
        display: "flex",
        justifyContent: "space-between",
        color: theme.gray,
        [theme.navBreak]: {
          display: "block",
        },
      }}
    >
      <p css={[theme.headerHighlight, { margin: 0 }]}>{text}</p>

      <div
        css={{
          flexShrink: 0,
          alignSelf: "start",
          marginLeft: 25,
          [theme.navBreak]: {
            marginTop: 25,
            marginLeft: 0,
          },
        }}
      >
        <CTAButton
          colorScheme="light"
          iconName="arrow"
          size="sm"
          href={button.url}
          onClick={onNavigate}
        >
          {button.title}
        </CTAButton>
        {textLink && <HighlightLink {...textLink} />}
      </div>
    </div>
  </HighlightBase>
)

export const LinksHighlight = ({ links, files, button, ...rest }) => (
  <HighlightBase {...rest}>
    <div
      css={{
        display: "flex",
        justifyContent: "space-between",
        marginTop: 18,
        [theme.navBreak]: {
          display: "block",
        },
      }}
    >
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
        }}
      >
        {links.map(link => (
          <DropdownLink
            {...link}
            key={link.title}
            css={{
              [theme.navBreak]: {
                borderBottomColor: "rgba(114, 205, 244, 0.6)",
              },
            }}
          />
        ))}
      </div>

      <div
        css={{
          gridRow: "2 / span 3",
          gridColumn: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {files.map(file => (
          <DropdownLink
            {...file}
            key={file.title}
            css={{
              [theme.navBreak]: {
                borderBottomColor: "rgba(114, 205, 244, 0.6)",
              },
            }}
          />
        ))}
      </div>

      <div
        css={{
          justifySelf: "start",
          marginLeft: 10,
          flexShrink: 0,
          marginTop: 18,
          [theme.navBreak]: {
            display: "none",
          },
        }}
      >
        <AudioPlayerButton {...button} />
      </div>
    </div>
  </HighlightBase>
)

const linkType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
})

HighlightLink.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

HighlightBase.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.shape({
    hotspot: PropTypes.object,
    crop: PropTypes.object,
    asset: PropTypes.object.isRequired,
  }).isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

TextHighlight.propTypes = {
  text: PropTypes.string.isRequired,
  button: linkType.isRequired,
  textLink: linkType,
  onNavigate: PropTypes.func,
}

LinksHighlight.propTypes = {
  links: PropTypes.arrayOf(linkType),
  files: PropTypes.arrayOf(linkType),
  button: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    fileURL: PropTypes.string.isRequired,
  }).isRequired,
}

Highlight.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.shape({
    hotspot: PropTypes.object,
    crop: PropTypes.object,
    asset: PropTypes.object.isRequired,
  }).isRequired,
  type: PropTypes.oneOf(["textHighlight", "linksHighlight"]).isRequired,
  body: PropTypes.string,
  links: PropTypes.arrayOf(linkType),
  files: PropTypes.arrayOf(linkType),
  button: PropTypes.oneOfType([
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
      fileURL: PropTypes.string.isRequired,
    }),
    linkType,
  ]).isRequired,
}
