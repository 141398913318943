import React, { forwardRef } from "react"
import PropTypes from "prop-types"
import ScrollLock from "components/ScrollLock"
import FocusTrap from "focus-trap-react"
import { keyframes } from "@emotion/react"
import useOnClickOutside from "hooks/useOnClickOutside"
import useOnKeydown from "hooks/useOnKeydown"
import InvisibleButton from "components/buttons/InvisibleButton"
import theme from "styles/theme"

const Modal = ({
  mode = "contained",
  animationDuration = "2s",
  children,
  onRequestClose,
  className,
}) => {
  const modalRef = useOnClickOutside(onRequestClose)

  useOnKeydown("Escape", onRequestClose)

  const contained = mode === "contained"

  return (
    <ScrollLock isActive={true}>
      {forwardRef((props, ref) => (
        <div
          ref={ref}
          css={{
            animation: `${overlayIn} ${animationDuration} cubic-bezier(0.25, 0.25, 0.25, 1)`,
            background: "hsla(0, 0%, 20%, 0.8)",
            backdropFilter: "blur(4px)",
            display: "flex",
            justifyContent: "center",
            alignItems: contained ? "center" : "start",
            position: "fixed",
            overflowY: "auto",
            zIndex: 50,
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            width: "100%",
            padding: "var(--modalPadding)",
            transform: "backdrop-filter 250ms",
            WebkitOverflowScrolling: "touch",
          }}
        >
          <FocusTrap>
            <div
              ref={modalRef}
              css={{
                transformOrigin: "bottom center",
                animation: `${contentIn} 500ms cubic-bezier(0.25, 0.25, 0.25, 1)`,
                position: "relative",
                width: !contained && "100%",
              }}
              className={className}
            >
              <InvisibleButton
                onClick={onRequestClose}
                aria-label="Close dialog"
                css={{
                  "--strokeColor": theme.lightYellow,
                  "--fillColor": theme.gray,
                  [[":hover", ":focus", ":focus-within"]]: {
                    "--strokeColor": theme.lightBlue,
                    "--fillColor": theme.darkGray,
                  },
                }}
              >
                <svg
                  viewBox="0 0 52 52"
                  css={{
                    "--iconSize": "52px",
                    "--iconOffset": "calc(var(--iconSize) * -0.5)",
                    position: "absolute",
                    top: contained
                      ? "var(--iconOffset)"
                      : "calc(var(--iconSize) * 0.6)",
                    right: contained
                      ? "var(--iconOffset)"
                      : "calc(var(--iconSize) * 0.6)",
                    width: "var(--iconSize)",
                    filter: "drop-shadow(-3px 3px 4px rgba(0, 0, 0, 0.25))",
                    cursor: "pointer",
                    zIndex: 51,
                    path: {
                      transition: "fill 250ms, stroke 250ms",
                    },
                    [theme.tablet]: { "--iconSize": "48px" },
                    [theme.mobile]: { "--iconSize": "36px" },
                  }}
                >
                  <path
                    fill="var(--fillColor)"
                    stroke="var(--strokeColor)"
                    strokeWidth="2"
                    d="M26 51a25 25 0 100-50 25 25 0 000 50zm6.5-31.5l-13 13m13 0l-13-13"
                  />
                </svg>
              </InvisibleButton>

              {children}
            </div>
          </FocusTrap>
        </div>
      ))}
    </ScrollLock>
  )
}

export default Modal

// Keyframe Animations
const overlayIn = keyframes({
  from: {
    background: "rgba(86, 90, 92, 0.0)",
    backdropFilter: "blur(0px)",
  },
  "50%": {},
  to: {
    background: "hsla(0, 0%, 20%, 0.8)",
    backdropFilter: "blur(5px)",
  },
})

const contentIn = keyframes({
  from: {
    transform: "translateY(200px) scale(0.5)",
    opacity: 0,
  },
  to: {
    transform: "translateY(0) scale(1)",
    opacity: 1,
  },
})

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  className: PropTypes.string,
  mode: PropTypes.oneOf(["contained", "full"]),
  animationDuration: PropTypes.string,
}
