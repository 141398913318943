import React, { useState } from "react"
import PropTypes from "prop-types"

export const AppContext = React.createContext({ searchOpen: false })

const App = ({ children }) => {
  const [searchOpen, setSearchOpen] = useState(false)

  return (
    <AppContext.Provider value={{ searchOpen, setSearchOpen }}>
      {children}
    </AppContext.Provider>
  )
}

export default App

App.propTypes = {
  children: PropTypes.node.isRequired,
}
