import React from "react"
import PropTypes from "prop-types"
import { jsx } from "@emotion/react"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import theme from "styles/theme"

import { ReactComponent as InstagramIcon } from "images/footer/socialInstagram.svg"
import { ReactComponent as LinkedInIcon } from "images/footer/socialLinkedIn.svg"
import { ReactComponent as TwitterIcon } from "images/footer/socialTwitter.svg"
import { ReactComponent as YouTubeIcon } from "images/footer/socialYouTube.svg"

const SocialLinks = ({ linkedin, twitter, instagram, youtube, className }) => (
  <div
    css={{
      display: "flex",
      alignItems: "center",
    }}
    className={className}
  >
    {linkedin && <SocialLink icon={LinkedInIcon} link={linkedin} />}
    {twitter && <SocialLink icon={TwitterIcon} link={twitter} />}
    {instagram && <SocialLink icon={InstagramIcon} link={instagram} />}
    {youtube && <SocialLink icon={YouTubeIcon} link={youtube} />}
  </div>
)

export default SocialLinks

SocialLinks.propTypes = {
  className: PropTypes.string,
  linkedin: PropTypes.string,
  twitter: PropTypes.string,
  instagram: PropTypes.string,
  youtube: PropTypes.string,
}

const SocialLink = ({ icon, link }) => (
  <OutboundLink
    href={link}
    target="_blank"
    rel="noopener noreferrer"
    css={{
      color: theme.lightBlue30,
      marginRight: 8,
      transition: "color 250ms",
      ":last-child": {
        marginRight: 0,
      },
      ":hover": {
        color: theme.lightBlue,
      },
    }}
  >
    {jsx(icon, {
      css: {
        width: 32,
      },
    })}
  </OutboundLink>
)

SocialLink.propTypes = {
  icon: PropTypes.func.isRequired,
  link: PropTypes.string.isRequired,
}
