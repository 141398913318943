import { useState, useEffect, useRef } from "react"
import fetchJsonp from "fetch-jsonp"

const parser = typeof window !== "undefined" ? new window.DOMParser() : {}
const parseHtml = data => parser.parseFromString(data, "text/html")

const STOCK_DATA_URL =
  "https://sslwidgetmaster.investorroom.com/widget/e2FHhsjHWAPk"

export const DEFAULT_DATA = {
  exchange: "NYSE",
  symbol: "MRO",
  price: "$0.00",
  open: "$0.00",
  volume: "0M",
  change: "$0.00",
  changeAmount: "0.00",
  changeDirection: 0,
  changePercent: "0%",
  yearHigh: "$0.00",
  yearLow: "$0.00",
}

const useStockData = () => {
  const [stockData, storeResponse] = useState(DEFAULT_DATA)
  const ref = useRef()

  useEffect(() => {
    ref.current = true

    fetchJsonp(STOCK_DATA_URL)
      .then(response => response.json())
      .then(data => {
        // If cleanup function has run, abort update
        if (!ref.current) return

        const doc = parseHtml(data.content)

        const [yearLow, yearHigh] = doc
          .querySelector(".wd_data4 .wd_value")
          .textContent.split(" - ")
        const changeData = doc.querySelector(".wd_data2 .wd_value").textContent
        const [, change, changePercent] = changeData.match(
          /(-?\$?[0-9.]+)\((-?[0-9.]+%)\)/
        )

        storeResponse({
          exchange: doc.querySelector("span.wd_exchange").textContent,
          symbol: doc.querySelector("span.wd_symbol").textContent,
          price: doc.querySelector(".wd_price > span").textContent,
          open: doc.querySelector(".wd_data1 .wd_value").textContent,
          volume: doc.querySelector(".wd_data5 .wd_value").textContent,
          change,
          changePercent,
          changeAmount: change.replace("$", ""),
          changeDirection: Math.sign(parseFloat(change.replace("$", ""))),
          yearHigh,
          yearLow,
        })
      })

    return () => {
      // Flag dismount to prevent async operations on unmounted component
      ref.current = false
    }
  }, [])

  return stockData
}

export default useStockData
