import React from "react"
import PropTypes from "prop-types"
import InvisibleButton from "components/buttons/InvisibleButton"
import theme from "styles/theme"

const dividerLine = `1px solid ${theme.slate}`

const NavButton = ({ text, icon, ...props }) => (
  <InvisibleButton
    css={{
      backgroundColor: theme.white,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "36px 30px",
      minWidth: 75,
      minHeight: 75,
      cursor: "pointer",
      color: theme.gray,
      borderRight: dividerLine,
      ":last-child": {
        borderRight: 0,
        borderLeft: dividerLine,
      },
      ":hover": {
        color: theme.midBlue,
        background: theme.blue20,
      },
      [theme.mobile]: {
        padding: 24,
      },
    }}
    {...props}
  >
    <div
      css={{
        fontWeight: 500,
        fontSize: 14,
        marginRight: icon && 12,
        [theme.mobile]: theme.visuallyHidden,
      }}
    >
      {text}
    </div>

    {icon}
  </InvisibleButton>
)

export default NavButton

NavButton.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.node,
}
