/* eslint-disable react/prop-types */
import React from "react"
import Helmet from "react-helmet"
import { CacheProvider, Global } from "@emotion/react"
import { hydrate, cache } from "@emotion/css"
import baseStyles from "styles/base"
import Layout from "components/Layout"
import App from "components/App"

export const wrapRootElement = ({ element }) => (
  <App>
    <CacheProvider value={cache}>{element}</CacheProvider>
  </App>
)

export const wrapPageElement = ({ element, props: { data, location } }) => {
  return (
    <>
      <Global styles={baseStyles} />
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          // NOTE IMPORTANT! referrerpolicy="origin" is not part of typography.com's
          // documentation, but vitally important for this link to not end up throwing
          // a 403 same-origin error.
          referrerpolicy="origin"
          href="https://cloud.typography.com/7865936/7789812/css/fonts.css"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <Layout>{element}</Layout>
    </>
  )
}

export const onClientEntry = () => {
  // This JSON script gets written in gatsby-ssr
  const idsTag = document.getElementById("hydrated-emotion-ids")

  if (idsTag) {
    const ids = JSON.parse(idsTag.innerHTML)
    hydrate(ids)
  }
}

export const onRouteUpdate = ({ location }) => scrollToAnchor(location, 96)

/**
 *
 * @desc - a function to jump to the correct scroll position
 * @param {Object} location -
 * @param {Number} [mainNavHeight] - the height of any persistent nav -> document.querySelector(`nav`)
 */
function scrollToAnchor(location, mainNavHeight = 0) {
  // Check for location so build does not fail
  if (location && location.hash) {
    const el = document.querySelector(`${location.hash}`)

    if (el) {
      window.scrollTo({
        top: el.offsetTop - mainNavHeight,
        behavior: "smooth",
      })
    }
  }

  return true
}
