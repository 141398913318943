import React, { useState, useCallback } from "react"
import PropTypes from "prop-types"
import theme from "styles/theme"
import useStockData from "hooks/useStockData"
import useOnClickOutside from "hooks/useOnClickOutside"
import useOnKeydown from "hooks/useOnKeydown"
import InvisibleButton from "components/buttons/InvisibleButton"

import { ReactComponent as StockArrow } from "images/icons/stockArrow.svg"
import { ReactComponent as StockPlus } from "images/icons/stockPlus.svg"

const StockTicker = () => {
  const data = useStockData()
  const [isOpen, setIsOpen] = useState(false)
  const close = useCallback(() => setIsOpen(false), [])

  const ref = useOnClickOutside(close, isOpen)
  useOnKeydown("Escape", close, ref)

  return (
    <div
      ref={ref}
      css={{
        position: "relative",
        width: 304,
        minHeight: 47,
        zIndex: 6,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <InvisibleButton
        css={{
          cursor: "pointer",
          maxHeight: 47,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          backgroundColor: theme.midBlue,
          transition: "box-shadow 250ms",
          boxShadow: isOpen
            ? theme.cardShadow
            : "1px 1px 3px rgba(0, 0, 0, 0.0)",
          zIndex: 7,
          userSelect: "none",
          ":hover": {
            boxShadow: theme.cardShadow,
            svg: {
              color: theme.lightOrange,
            },
          },
        }}
        onClick={() => {
          setIsOpen(prev => !prev)
        }}
        aria-label={`${isOpen ? "Collapse" : "Expand"} Stock Ticker. Symbol: ${
          data.symbol
        }, Price: ${data.price}, ${
          data.changeDirection > 0 ? "up" : "down"
        } by ${data.change}`}
      >
        <div
          css={[
            theme.headerBold,
            {
              minWidth: 79,
              color: theme.nearWhite,
              backgroundColor: theme.deepBlue,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: 47,
              padding: "0px 20px",
            },
          ]}
        >
          {data.exchange}
        </div>
        <div
          css={{
            width: "100%",
            color: theme.nearWhite,
            backgroundColor: theme.midBlue,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingLeft: 19,
            paddingRight: 19,
            fontSize: 13,
            fontWeight: 500,
            textTransform: "uppercase",
            letterSpacing: "0.09em",
          }}
        >
          <div css={theme.headerBold}>{data.symbol}</div>
          <div>{data.price}</div>
          <div>
            <StockArrow
              css={{
                width: 6,
                marginRight: "0.25em",
                display: data.changeDirection === 0 ? "none" : "inline",
                transform:
                  data.changeDirection > 0 ? "rotate(180deg)" : "rotate(0deg)",
              }}
            />
            {data.changeAmount}
          </div>
          <div aria-hidden>
            <StockPlus
              css={{
                width: 16,
                transition: "transform 250ms",
                transform: isOpen ? "rotate(45deg)" : "rotate(0deg)",
                ":hover": {
                  color: theme.lightOrange,
                },
              }}
            />
          </div>
        </div>
      </InvisibleButton>

      <div
        css={{
          position: "absolute",
          top: "100%",
          width: "100%",
          backgroundColor: theme.midBlue,
          color: theme.white,
          padding: 20,
          borderTop: `1px solid ${theme.white}`,
          boxShadow: isOpen
            ? theme.cardShadow
            : "0px 0px 0px rgba(0, 0, 0, 0.0)",
          opacity: isOpen ? 1 : 0,
          transitionProperty: "transform, opacity, box-shadow",
          transitionDuration: "250ms",
          transform: isOpen ? "translateY(0)" : "translateY(-100%)",
          zIndex: 5,
          fontSize: 11,
          letterSpacing: "-0.01em",
        }}
        aria-hidden={!isOpen}
      >
        <div>
          <StockDetail title="Open" value={data.open} />
          <StockDetail title="Change" value={data.change} />
          <StockDetail title="Change %" value={data.changePercent} />
          <StockDetail title="Volume" value={data.volume} />
          <StockDetail title="52-Week High" value={data.yearHigh} />
          <StockDetail title="52-Week Low" value={data.yearLow} />
        </div>

        <div
          css={{
            textAlign: "center",
            padding: "4px 0 1px",
          }}
        >
          Quote data delayed by at least 15 minutes
        </div>
      </div>
    </div>
  )
}

export default StockTicker

const StockDetail = ({ title, value }) => (
  <>
    <div
      css={{
        display: "flex",
        justifyContent: "space-between",
        padding: "8px 0",
        fontWeight: 500,
        textTransform: "uppercase",
      }}
      // "text" is valid here
      // eslint-disable-next-line jsx-a11y/aria-role
      role="text"
    >
      <span>{title}</span>
      <span>{value}</span>
    </div>

    <svg
      css={{ "&:last-child": { display: "none" } }}
      height="1"
      viewBox="0 0 264 1"
      aria-hidden
    >
      <path d="M0.5 0.5H263.5" stroke="white" strokeDasharray="1 3" />
    </svg>
  </>
)

StockDetail.propTypes = {
  title: PropTypes.node.isRequired,
  value: PropTypes.node.isRequired,
}
