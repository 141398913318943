import { useEffect } from "react"

/**
 * Triggers a function when a key is pressed. Can filter for specific keys and/or
 * limit listening to a specified scope/element.
 *
 * @param {Array<String>|String} key The plaintext key to listen for (e.g. "ArrowDown") or an array of keys to listen for (e.g. ["ArrowDown", "ArrowUp"])
 * @param {Function} callback The callback to run when the key is pressed
 * @param {import("react").DOMElement} scope The DOM element to listen for key events on
 */
const useOnKeydown = (key, callback, scope) => {
  useEffect(() => {
    const handleEvent = event => {
      if (
        (Array.isArray(key) && key.includes(event.key)) ||
        (key && key === event.key) ||
        !key
      ) {
        console.debug("[useOnKeydown.handleEvent | key captured]", event.key)
        callback(event)
      }
    }

    const target = scope?.current || document
    console.debug("[useOnKeydown | listening for key on target]", key, target)

    target.addEventListener("keydown", handleEvent)

    return () => {
      target.removeEventListener("keydown", handleEvent)
    }
  }, [key, callback, scope])
}

export default useOnKeydown
